import React, { Component } from 'react';
import ProjectCards from '../ProjectCards';
import Footer from '../Footer';
import MyMenu from '../Menu';
import { HashLink as Link } from 'react-router-hash-link';

import './style.scss';

// Scroll with a offset
const scrollWidthOffset = (el) => {
	const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
	const yOffset = -20;
	window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' });
};

class App extends Component {
	constructor(props) {
		super(props);
		this.state = {
			listOfProjects: [],
			isIE11: !!window.MSInputMethodContext && !!document.documentMode
		};
	}

	render() {
		return (
			<div className="App">
				<header className="App-header">
					<MyMenu />
				</header>
				<div className="ui text container">
					<div className="intro">
						<div className="textWrapper">
							<h1>
								<span role="img" aria-label="waving hand">
									👋
								</span>{' '}
								Hi! Ich bin Patrick und gestalte und programmiere interaktive Stories sowie
								Daten&shy;visualisierungen für die digitalen Bezahltitel von Tamedia.
							</h1>
						</div>

						<Link smooth to="/portfolio#work" scroll={scrollWidthOffset} className="backNavigation">
							<button className="circular ui icon button portfolioLink floating">
								<i className="icon arrow down" />
							</button>
						</Link>
					</div>
				</div>
				<ProjectCards projectData={this.props.projectData} loaderState={this.props.loaderState} />
				<Footer />
			</div>
		);
	}
}

export default App;
