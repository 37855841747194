import React, { Component } from 'react';
// import { Cover } from '../PhoneCover';
import VideoPlayer from '../VideoPlayer/VideoPlayer.tsx';
import { HashLink as Link } from 'react-router-hash-link';

import './style.scss';

class Projects extends Component {
	//   constructor(props) {
	//     super(props);

	//     // this.state = {
	//     //     cards: [],
	//     // }
	// }

	// componentDidMount() {
	// Tabletop.init({
	//     key: 'https://docs.google.com/spreadsheets/d/1IKfZTdzb0u2iLCsMV-L3ecfscxhFoBzfC8LekWquBio/edit?usp=sharing',
	//     callback: data => {
	//         this.setState({
	//             listOfProjects: data
	//         })
	//         // console.log(data)
	//     },
	//     simpleSheet: true
	// })

	// GetSheetDone.labeledCols('1IKfZTdzb0u2iLCsMV-L3ecfscxhFoBzfC8LekWquBio').then(sheet => {
	//         this.setState({
	//             listOfProjects: sheet.data,
	//             request: 'success'
	//         })
	//         console.log(this.state.listOfProjects)
	// })

	// }

	renderProjects() {
		const filterProject = this.props.projectData.slice(this.props.match.params.id - 1, this.props.match.params.id);

		return filterProject.map((c, index) => {
			const listImagesDesc = c.ImagesDesc.split(',');
			const listImagesExtraClass = c.ImagesExtraClass.split(',');

			let picOneDesc = false;
			let picTwoDesc = false;
			let picThreeDesc = false;

			let aboPlus = false;
			let awards = false;

			let extraImageClassOne = false;
			let extraImageClassTwo = false;

			//Checks if index of array is there, If true -> set it to true

			//ExtraImageClass -> Check
			if (listImagesExtraClass[0] !== '') {
				extraImageClassOne = true;
			}

			if (listImagesExtraClass.length > 1) {
				extraImageClassTwo = true;
			}

			//ImageDesc -> Check
			if (listImagesDesc[0] !== '') {
				picOneDesc = true;
			}
			if (listImagesDesc.length > 1) {
				picTwoDesc = true;
			}
			if (listImagesDesc.length > 2) {
				picThreeDesc = true;
			}

			//Abo+ -> Check
			if (c.AboPlus !== '') {
				aboPlus = true;
			}

			if (c.Awards !== '') {
				awards = true;
			}

			let picOne =
				picOneDesc &&
				`<img src=${require(`./imgs/${listImagesDesc[0]}`)}
      			class="ui fluid image ${extraImageClassOne ? `${listImagesExtraClass[0]}` : ''} ${extraImageClassTwo
					? `${listImagesExtraClass[1]}`
					: ''}" alt="Projektbild" />`;

			let picTwo =
				picTwoDesc &&
				`<img src=${require(`./imgs/${listImagesDesc[1]}`)}
      			class="ui fluid image shadow" alt="Projektbild" />`;

			let picThree =
				picThreeDesc &&
				`<img src=${require(`./imgs/${listImagesDesc[2]}`)}
      			class="ui fluid image shadow" alt="Projektbild" />`;

			//Tools -> Check if not empty
			let tools = false;

			if (c.Tool !== '') {
				tools = true;
			}

			//If Layout two -> true than display two column project layout
			let layout = false;

			if (c.Layout === 'two') {
				layout = true;
			}

			return (
				<div className="ui container project" key={c}>
					<div className="buttonContainer">
						<Link
							to={'/portfolio#' + parseInt(c.Number)}
							scroll={(el) => {
								el.scrollIntoView(true);
								window.scrollBy(0, -18);
							}}
							className="backNavigation"
						>
							<button className="circular ui icon button back">
								<i className="arrow left icon" />
							</button>
						</Link>
					</div>

					<p className="date">
						{c.Month} {c.Year}
					</p>

					<h2 className="projectTitle" dangerouslySetInnerHTML={{ __html: c.Title }} />

					<div className="overview">
						<div className="row">
							<p className="overviewText">
								<i className="icon id badge outline" />
								{c.Customer}
							</p>
						</div>
						<div className="row">
							<i className="icon linkify" />
							<span className="linkProject" dangerouslySetInnerHTML={{ __html: c.URL }} />
							{aboPlus && <div className="premiumMarker">Abo</div>}
						</div>
					</div>

					<div className="ui grid projects stackable">
						<div className={layout ? 'eight wide column' : 'sixteen wide column'}>
							<p
								className="descriptionOne"
								dangerouslySetInnerHTML={{
									__html: c.DescriptionOne
										.replace('picOneDesc', picOne)
										.replace('picTwoDesc', picTwo)
										.replace('picThreeDesc', picThree)
								}}
							/>

							{tools && (
								<div className="tools mobileHidden">
									<div>
										<span className="toolsTitle">Tools:</span> {c.Tools}
									</div>
								</div>
							)}

							{awards && (
								<div className="row awards mobileHidden">
									<i className="icon trophy" />
									<div className="awardText" dangerouslySetInnerHTML={{ __html: c.AwardsText }} />
								</div>
							)}
						</div>
						{layout && (
							<div className={layout ? 'eight wide column two' : 'sixteen wide column'}>
								<VideoPlayer asset={c.PhoneCover} />

								{tools && (
									<div className="tools desktopHidden">
										<div>
											<span className="toolsTitle">Tools:</span> {c.Tools}
										</div>
									</div>
								)}
								{awards && (
									<div className="row awards desktopHidden">
										<i className="icon trophy" />
										<div className="awardText" dangerouslySetInnerHTML={{ __html: c.AwardsText }} />
									</div>
								)}
							</div>
						)}
					</div>

					<div dangerouslySetInnerHTML={{ __html: c.Tweet }} />
				</div>
			);
		});
	}

	render() {
		return (
			<div className="App">
				<div className={`ui vertical project`}>
					<div>{this.renderProjects()}</div>
				</div>
			</div>
		);
	}
}

export default Projects;
