import React, { Component } from 'react';
// import Tabletop from 'tabletop';
import { Link } from 'react-router-dom';
import './style.scss';


let currentLocation = window.location;
console.log(currentLocation.origin)


class ProjectCards extends Component {
  constructor(props) {
      super(props);

      this.state = {
          cards: [],
          listOfProjects: [],
      }
  }

  returnCards = () => {
    // if (this.state.cards.length > 0)
    return (

    <div className={`ui ${this.props.loaderState} vertical segment portfolio`}>

      <div className='ui container'>
        <div className='flagContainer'>
          <span className='flagWord' id='work'>Work</span>
          <p className='lead'>Hier zeige ich Dir einen Auszug meiner Arbeiten <span role='img' aria-label='man artist'>👨‍🎨</span><span role='img' aria-label='man technologist'>👨‍💻</span> der letzten Jahre.</p>
        </div>
        <div className='ui three cards doubling hans'>

        {/* {console.log('test', this.props.projectData)} */}

        {this.props.projectData.map((c, index) => {

            return (
                <Link
                  to={'/projects/' + c.Number}
                  key={c.Number + index}
                  data-link={c.Id}
                  className='ui card'
                  id={c.Number}
                >

                  <img src={require(`../imgs/${c.Teaser}`)} className={`ui fluid image ${
                      this.state.isHovering !== true ? 'grayscale' : 'Null'
                    }`} alt='' />

                  <div className='content'>
                    <div className='headerWrapper'>
                    <div className='date'>
                        <span>{c.Month} {c.Year}</span>
                      </div>
                      <div className='header' dangerouslySetInnerHTML={{ __html: c.Title }} />
                      <div className='description'>
                      {c.lead}
                    </div>
                    </div>
                  </div>
                </Link>

            )
        })}
        </div>
      </div>
    </div>

    )
  }

  render() {

    return (
        <>
            {this.returnCards()}
        </ >
    );
  }

}

export default ProjectCards;
