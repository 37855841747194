import React, { Component } from 'react';
import './style.scss';

export default class Footer extends Component {
	render() {
		return (
			<div className='ui vertical segment footer'>
				<div className="ui container">
                    <div className='mainRow'>
						<div className="imageContainer">
							<img src={require('../imgs/logo.svg')} className="ui fluid image" alt="" />
						</div>
						<div className='copyright'>© Patrick Vögeli</div>


                    </div>
				</div>
				</div>
		);
	}
}
