import React, { Component } from 'react';
import { Menu } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { NavHashLink as NavLink } from "react-router-hash-link";

import './style.scss';

const scrollWidthOffset = el => {
  const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
  const yOffset = -20;
  window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
};

export default class MyMenu extends Component {

    render() {

      return (

        <div className="ui container">
				<Menu text size="small">
          <Menu.Item as={Link} to='/portfolio'>
            <img src={require(`../imgs/logo.svg`)} className='logo' alt="header" />
          </Menu.Item>

					<Menu.Menu position="right">
						<Menu.Item
							as={NavLink}
							to="/ueber-mich"
							name="Über mich"
              content="Über mich"
						/>

						<Menu.Item
							as={NavLink}
							to={{
                pathname: "/portfolio",
                hash: "#work",
              }}
              scroll={scrollWidthOffset}
							name="Portfolio"
              content="Portfolio"
						/>

					</Menu.Menu>
				</Menu>
			</div>
      )
    }
}
