import * as React from 'react'

import './styles.scss';

const isMobile = window.innerWidth < 5000;

type Props = {  
  asset: string
  autoplay?: boolean
}

const VideoPlayer: React.FC<Props> = ({ asset, autoplay = false }) => {

  const videoRef = React.useRef<HTMLVideoElement>(null)
  const [playing, setPlaying] = React.useState<boolean>(false)

  const togglePlaying = (): void => {
    if (videoRef.current.paused) {
      videoRef.current.play()
    } else {
      videoRef.current.pause()
    }
  }

  React.useEffect(() => {
    if (videoRef.current && window.IntersectionObserver) {
      const observer = new IntersectionObserver(
        entries => {
          entries.forEach(e => {
            if (videoRef.current) {
              if (e.intersectionRatio >= 0.0) {
                videoRef.current.play()
              } else {
                videoRef.current.pause()
              }
            }
          })
        },
        { root: null, rootMargin: '0px', threshold: 0.0 }
      )
      observer.observe(videoRef.current)
    }
  }, [])

  return (
    <div className={'contentWrapper'}>
      <div className={'videoWrapper'}>
        <div className={'videoBox'}>
          {/* <div className={'cut'}> */}
            <video
              ref={videoRef}            
              className={'ui fluid image video'}
              autoPlay={autoplay}
              controls={false}
              loop={true}
              onClick={togglePlaying}
              onPlay={(): void => setPlaying(true)}
              onPause={(): void => setPlaying(false)}
              muted={true}
              playsInline={true}
              poster={require(`./imgs/${asset}.jpg`)}
            >
                 {isMobile && <source src={require(`./imgs/${asset}.mp4`)} type='video/mp4' />}
                 {isMobile && <source src={require(`./imgs/${asset}.webm`)} type='video/webm' />}
              {/* <source src={require(`./imgs/${asset}.mp4`)} type='video/mp4' /> */}
            </video>
          {/* </div> */}
        </div>
      </div>
      <div className={'control'}>
        <svg width={40} height={40} onClick={togglePlaying}>
          <circle
            cx={20}
            cy={20}
            r={17}
            stroke='#666'
            strokeWidth={2}
            fill='none'
          />
          {playing ? (
            <g>
              <polygon points='13,12 13,28 18,28 18,12' fill='#666' />
              <polygon points='22,12 22,28 27,28 27,12' fill='#666' />
            </g>
          ) : (
            <polygon points='14,11 14,28 30,20' fill='#666' />
          )}
        </svg>
      </div>
    </div>
  )
}

export default VideoPlayer