import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Route, Switch } from 'react-router-dom';
import Home from './components/Home';
import About from './components/About';
import Contact from './components/Contact';
import Projects from './components/Projects';
import Papa from 'papaparse';

import ScrollToTop from './components/ScrollToTop';

import 'semantic-ui-css/semantic.min.css';
// import '@ta-interaktiv/semantic-ui/semantic/dist/components/reset.css'
// import "@ta-interaktiv/semantic-ui/semantic/dist/components/site.css";
// import "@ta-interaktiv/semantic-ui/semantic/dist/components/container.css";
// import "@ta-interaktiv/semantic-ui/semantic/dist/components/image.css";
// import "@ta-interaktiv/semantic-ui/semantic/dist/components/list.css";
// import "@ta-interaktiv/semantic-ui/semantic/dist/components/segment.css";
// import "@ta-interaktiv/semantic-ui/semantic/dist/components/card.css";
// import "@ta-interaktiv/semantic-ui/semantic/dist/components/loader.css";
// import "@ta-interaktiv/semantic-ui/semantic/dist/components/menu.css";
// import "@ta-interaktiv/semantic-ui/semantic/dist/components/grid.css";
// import "@ta-interaktiv/semantic-ui/semantic/dist/components/icon.css";
// import "@ta-interaktiv/semantic-ui/semantic/dist/components/button.css";

import './index.scss';
import './_iphone-x.scss';

class Root extends React.Component {
	constructor() {
		super();
		this.state = {
			listOfProjects: [],
			request: 'loading'
		};
	}

	componentDidMount() {
		fetch(
			'https://docs.google.com/spreadsheets/d/e/2PACX-1vQzlm2oDZgz5ZIEwekwMqIy5-xW8-QXcqLiviDcoL52weyKzgNksyyC9tpR-rtJ7bFD6TuLhXH9cCT-/pub?output=csv'
		)
			.then((res) => res.text())
			.then((data) => {
				let dataCSV = Papa.parse(data, { header: true }).data;
				this.setState({
					listOfProjects: dataCSV,
					request: 'success'
				});
				console.log(dataCSV);
			})
			.catch((err) => {
				console.log(err.message);
			});
	}

	render() {
		return (
			<Router basename={''}>
				<ScrollToTop>
					<Switch>
						<Route
							exact
							path="/"
							render={(props) => (
								<Home
									projectData={this.state.listOfProjects}
									loaderState={this.state.request}
									{...props}
								/>
							)}
						/>
						<Route exact path="/ueber-mich" component={About} />
						<Route
							exact
							path="/portfolio"
							render={(props) => (
								<Home
									projectData={this.state.listOfProjects}
									loaderState={this.state.request}
									{...props}
								/>
							)}
						/>
						<Route exact path="/contact" component={Contact} />
						<Route
							path="/projects/:id"
							render={(props) => <Projects projectData={this.state.listOfProjects} {...props} />}
						/>
						<Route
							render={() => (
								<div className="errorPage">
									<h1>404 Error</h1>
								</div>
							)}
						/>
					</Switch>
				</ScrollToTop>
			</Router>
		);
	}
}

ReactDOM.render(<Root />, document.getElementById('root'));
