import React, { Component } from 'react';
import MyMenu from '../Menu'

class Contact extends Component {
    render() {
      return (
        <div className="App">
          <header className="App-header">
              <MyMenu />
          </header>
          <div>Contact</div>

        </div>
      );
    }
  }

  export default Contact;
