import React, { Component } from 'react';
import MyMenu from '../Menu';
import './style.scss';

class About extends Component {
	render() {
		return (
			<div className="App">
				<header className="App-header">
					<MyMenu />
				</header>
				<div className="ui vertical segment about">
					<div className="ui text container image">
						<div className="imageWrapper">
						<img
							src={require("../imgs/portrait-patrickvoegeli.jpg")}
							// className="ui fluid image"
							alt="portrait"
							// style={{
							// 	maxWidth: '60%',
							// 	minWidth: '200px',
							// 	marginLeft: 'auto',
							// 	marginRight: 'auto',
							// 	borderRadius: '100%',
							// 	backgroundColor: '#ececec'
							// }}
						/>
						</div>
					</div>
					<div className="ui text container" style={{ marginTop: '2em' }}>
						<div className="aboutTitle">
							<h2>Design</h2>
							<h2>Code</h2>
							<h2>Storytelling</h2>
						</div>
						<div className="socialContainer">
							<a href="https://twitter.com/PVoegeli" className="socialIcon">
								<button className="circular ui icon button socialLink">
									<i className="twitter icon" />
								</button>
							</a>

							<a href="https://www.linkedin.com/in/patrickvoegeli" className="socialIcon">
								<button className="circular ui icon button socialLink">
									<i className="linkedin icon" />
								</button>
							</a>
						</div>{' '}
						<br />
						<p>
							Seit dem <b>Frühjahr 2018</b> arbeite ich als{' '}
							<b>Interaction Designer und Multimedia Storyteller</b> für die digitalen Bezahltitel von
							Tamedia. In meiner Funktion im Interaktiv-Team des Tages-Anzeigers produziere ich Beiträge mit interaktiven Anwendungen, Animationen,
							Informationsgrafiken, Datenvisualisierungen sowie neue Storytelling-Formate und
							Darstellungsformen für den digitalen Kanal der Redaktion Tamedia. Nebst der Informationsvisualisierung und Programmierung, gehören
							ebenso analytische und konzeptionelle Aufgaben zu meinen täglichen Arbeiten.
						</p>
						<p>
							Nach einer Lehre als Kaufmann bildete ich mich im Bereich Marketing und Kommunikation zum{' '}
							<b>eidg. Marketingfachmann FA (2009-2011)</b> weiter. Als Marketing Manager fungierte ich
							anschliessend für mehrere Jahre als Bindeglied in der Kommunikation zwischen Unternehmen und
							externen Anspruchsgruppen wie Agenturen und Dienstleistern. Dabei konnte ich mir
							vielseitiges und fundiertes Fachwissen in der konzeptionellen und strategischen
							Kommunikation aneignen, bevor ich mir mit dem{' '}
							<b>Bachelor of Science in Media Engineering/Multimedia Production (2014–2017)</b> an der
							Fachhochschule Ostschweiz und der Berner Fachhochschule die Kenntnisse in der Produktion von
							multimedialen Inhalten sowie die dafür erforderlichen Gestaltungs-, Medien- und
							Programmiertechnik aneignete.{' '}
						</p>
						<p>
							<b>Ende 2018</b> absolvierte ich einen sechsmonatigen Studiengang zum{' '}
							<b>Full Stack Developer</b> an der Propulsion Academy Zürich (ETH-Spin-off) mit den
							inhaltlichen Schwerpunkten JavaScript, HTML, CSS, NodeJS, ExpressJS, Datenbanken, Webpack
							und React.js.
						</p>
					</div>
				</div>
			</div>
		);
	}
}

export default About;
